import React from 'react';
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti';
 
const ConfettiAnimation = () => {
  // const { width, height } = useWindowSize()
  return (
    <Confetti
      // width={window.innerWidth}
      // height={window.innerHeight}
    />
  )
}
 export default ConfettiAnimation;